module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-103580680-1"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{"send_page_view":true,"anonymize_ip":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e776f86abd6010292e77a298926e3550"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Akshay Shrivastav Portfolio","short_name":"AS Portfolio","description":"Crafting fast and secure enterprise-level applications is my forte. Excelling in Full Stack Web Development, technical leadership, and modernizing applications.","start_url":"/?source=pwa","scope":"https://www.akshayshrivastav.me","display":"standalone","icon":"src/assets/images/icon.png","icon_options":{"purpose":"any"},"include_favicon":true,"theme_color":"#3e64ff","background_color":"#f5f5f5","theme_color_in_head":true,"cache_busting_mode":"name","legacy":true,"crossOrigin":"use-credentials","cacheDigest":"e776f86abd6010292e77a298926e3550"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/portfolio","/skills","services","projects","contact"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
