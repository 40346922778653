import React from 'react';

import './index.scss';

const Loader: React.FC = () => {
	return (
		<div className="portfolio-loader">
			<div className="portfolio-loader__inner">
				<div className="portfolio-loader__screen">
					<div className="portfolio-loader__face-one">
						<div className="portfolio-loader__camera"></div>
						<div className="portfolio-loader__display">
							<div className="portfolio-loader__shade">
								&lt; LOADING /&gt;
							</div>
						</div>
						<span>Akshay's Mac</span>
					</div>
					<div className="portfolio-loader__back">
						Akshay Shrivastav
					</div>
				</div>
				<div className="portfolio-loader__body">
					<div className="face-one">
						<div className="touchpad">
						</div>
						<div className="keyboard">
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key space"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key"></div>
							<div className="key f"></div>
							<div className="key f"></div>
							<div className="key f"></div>
							<div className="key f"></div>
							<div className="key f"></div>
							<div className="key f"></div>
							<div className="key f"></div>
							<div className="key f"></div>
							<div className="key f"></div>
							<div className="key f"></div>
							<div className="key f"></div>
							<div className="key f"></div>
							<div className="key f"></div>
							<div className="key f"></div>
							<div className="key f"></div>
							<div className="key f"></div>
						</div>
					</div>
					<div className="pad one"></div>
					<div className="pad two"></div>
					<div className="pad three"></div>
					<div className="pad four"></div>
				</div>
			</div>
			<div className="portfolio-loader__shadow"></div>
		</div>

	);
};

export default Loader;
