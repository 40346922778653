/**
 * Gatsby Browser File
 * Refer: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 *
 * Logic/code you want to run on client side, can we written here.
 */

import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import Loader from './src/components/Loader';

let root: Root | null = null;

const mainID: string = '___gatsby';
const loaderID: string = '___loader';

export const onClientEntry = () => {
	const loader = document.createElement( 'div' );

	loader.id = loaderID;
	document.body.appendChild( loader );

	root = createRoot( loader );
	root.render( <Loader /> );
};

export const onInitialClientRender = () => {
	const loader = document.getElementById( loaderID );
	const canvasID = document.getElementById( mainID );

	if ( loader ) {
		setTimeout( () => {
			if ( root ) {
				root.unmount();

				document.body.style.overflow = 'auto';

				if ( loader.parentNode ) {
					loader.parentNode.removeChild( loader );

					if ( canvasID ) {
						canvasID.style.opacity = '1';
					}
				}

				root = null;
			}
		}, 1000 );
	}
};
